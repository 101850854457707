.tree-container[data-v-85d7136d] {
  height: 95%;
  overflow: auto;
}
.tree-container .input_filter[data-v-85d7136d] {
  margin-bottom: 8px;
}
[data-v-85d7136d] .el-checkbox {
  margin-bottom: 0;
}
[data-v-85d7136d] .el-checkbox__original {
  display: none;
}
