#row_content[data-v-6c8fb6fd] {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: 'space-between';
  flex-wrap: nowrap;
  background-color: var(--6c8fb6fd-bgColor);
}
#row_content #title_and_icon[data-v-6c8fb6fd] {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
}
#row_content #title_and_icon #work_list_title[data-v-6c8fb6fd] {
  display: inline-block;
  background-color: var(--6c8fb6fd-bgColor);
  color: var(--6c8fb6fd-color);
  font-weight: var(--6c8fb6fd-weight);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#row_content .button_and_icon[data-v-6c8fb6fd] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40px;
}
#row_content .button_and_icon .icon_star[data-v-6c8fb6fd] {
  margin-right: 10px;
}
#row_content .button_and_icon .icon_plus[data-v-6c8fb6fd] {
  margin-right: 2px;
}
.core_circle[data-v-6c8fb6fd] {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-right: 10px;
}
