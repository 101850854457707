.title[data-v-51977e8a] {
  height: 75px;
  line-height: 75px;
}
.detail[data-v-51977e8a] {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.detail .table_container[data-v-51977e8a] {
  height: 93%;
  width: 100%;
  overflow: hidden;
}
.detail .pagination[data-v-51977e8a] {
  height: 7%;
  width: 100%;
  display: flex;
  justify-content: center;
}
