.toolbar[data-v-7d55e521] {
  display: flex;
  flex-direction: var(--7d55e521-flexDirection);
  flex-wrap: wrap;
}
.toolbar .input-container[data-v-7d55e521] {
  white-space: nowrap;
  margin-right: 8px;
  margin-bottom: 8px;
}
.toolbar .input-container .input[data-v-7d55e521] {
  min-width: 120px;
}
