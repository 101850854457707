.table[data-v-3a025394] {
  width: 100%;
  height: 95%;
  margin: 0;
}
[data-v-3a025394] .ag-paging-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}
